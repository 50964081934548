import React, { useEffect, useMemo, useState } from "react"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { GlobalValuesProvider } from "../globalValues"
import { breakpoints } from "../styles"
import styled, { css } from "styled-components"
import {
  COLOR_BLUE,
  COLOR_ERROR,
  COLOR_GRAY_300,
  COLOR_GRAY_400,
  COLOR_GRAY_900,
  COLOR_STONE,
  COLOR_SUCCESS,
  SPACE_XL,
} from "../styles/primitives"
import { MAX_WIDTH } from "../styles/breakpoints"
import { ThemeProvider } from "../styles/themes"
import Layout from "../components/Layout"
import {
  ClockIcon,
  DeliveryIcon2,
  LoaderIcon,
  RawCloseIcon,
} from "../icons/icons"
import { CheckmarkIcon } from "../icons/CheckmarkIcon"
import { getOrderStatusApi } from "../services/netsuite"
import SEO from "../components/seo"
import { getQueryStringValue } from "../utils/slug"

export const query = graphql`
  query OrderStatusPageQuery(
    $mainContentfulId: String!
    $node_locale: String!
    $contentful_id: String!
  ) {
    allContentfulBuyUnuPage(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        __typename
        node_locale
        slugs {
          orderComplete
        }
      }
    }

    contentfulBuyUnuPage(node_locale: { eq: $node_locale }) {
      title
      metaDescription
      slug
      orderCompleteTitle

      orderStatusLabels {
        id
        deliveredText
        deliveredTitle
        deliveryOnTheWayText
        engine
        deliveryOnTheWayTitle
        modelName
        paymentNotReceivedText
        paymentNotReceivedTitle
        paymentReceivedText
        paymentReceivedTitle
        title
        trackOrderTitle
        type
        unuOrderNotReceivedText
        unuOrderNotReceivedTitle
        unuOrderReceivedText
        unuOrderReceivedTitle
        vehicleBrand
        vehicleClass
        vin
        producerKey
        version
        yourScooterText
        yourScooterTitle
        orderLoadingErrorTitle
        orderLoadingErrorText

        customizationFinishedTitle
        customizationFinishedText
        customizationNotFinishedTitle
        customizationNotFinishedText

        unuOrderCanceledTitle
        unuOrderCanceledText
      }
    }
    ...GlobalValues
    ...DefaultMainValues
  }
`

const OrderOverviewPageBody = ({ page, shipUnuPageSlug }) => {
  const location = useLocation()
  const { href } = location
  // add fallback for buildtime, we want this to work on runtime
  const orderGuid = getQueryStringValue(href || "", "order") || null

  const [orderStatusState, setOrderStatusState] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    console.log("useEffect on orderStatusPage: ", orderGuid)
    async function loadOrderStatusState() {
      // on build-time don't try loading an order, we'll be missing the "order" query parameter
      // we'll do that on runtime, for buildtime, just render the loader
      try {
        setLoading(true)
        const orderStatusState = await getOrderStatusApi(orderGuid)
        console.log("orderStatusState: ", orderStatusState)
        setOrderStatusState(orderStatusState)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setError(error)
        setLoading(false)
      }
    }

    loadOrderStatusState()
  }, [orderGuid])

  const trackOrderSteps = useMemo(() => {
    let statuses = []

    if (!orderStatusState) return statuses

    if (orderStatusState?.orderStatus?.[0].orderReceived === true) {
      statuses.push({
        title: page.orderStatusLabels.unuOrderReceivedTitle,
        date: orderStatusState.orderStatus[0].receivedDate,
        text: page.orderStatusLabels.unuOrderReceivedText,
        completed: true,
      })
    } else {
      statuses.push({
        title: page.orderStatusLabels.unuOrderNotReceivedTitle,
        date: null,
        text: page.orderStatusLabels.unuOrderNotReceivedText,
        completed: false,
      })
    }

    if (orderStatusState?.paymentStatus?.[0].paymentReceived === true) {
      statuses.push({
        title: page.orderStatusLabels.paymentReceivedTitle,
        date: orderStatusState.paymentStatus[0].receivedDate,
        text: page.orderStatusLabels.paymentReceivedText,
        completed: true,
      })
    } else {
      statuses.push({
        title: page.orderStatusLabels.paymentNotReceivedTitle,
        date: null,
        text: page.orderStatusLabels.paymentNotReceivedText,
        completed: false,
      })
    }

    if (orderStatusState.customizationStatus?.[0].willBeCustomized === true) {
      if (orderStatusState.customizationStatus?.[0].customizedFinished) {
        statuses.push({
          title: page.orderStatusLabels.customizationFinishedTitle,
          date: orderStatusState.customizationStatus?.[0]?.customizedEndDate,
          text: page.orderStatusLabels.customizationFinishedText,
          completed: true,
        })
      } else {
        statuses.push({
          title: page.orderStatusLabels.customizationNotFinishedTitle,
          date: orderStatusState.customizationStatus?.[0]?.customizedEndDate,
          text: page.orderStatusLabels.customizationNotFinishedText,
          completed: false,
        })
      }
    }

    if (orderStatusState.paymentStatus?.[0].paymentReceived === true) {
      if (orderStatusState.deliveryStatus?.[0].delivered === true) {
        statuses.push({
          title: page.orderStatusLabels.deliveredTitle,
          date: orderStatusState.deliveryStatus[0]?.scheduledDeliveryDate,
          text: page.orderStatusLabels.deliveredText,
          completed: true,
          isDelivery: true,
        })
      } else if (orderStatusState.deliveryStatus?.[0].scheduledDeliveryDate) {
        statuses.push({
          title: page.orderStatusLabels.deliveryOnTheWayTitle,
          date: orderStatusState.deliveryStatus[0]?.scheduledDeliveryDate,
          text: page.orderStatusLabels.deliveryOnTheWayText,
          completed: false,
          isDelivery: true,
        })
      }
    }

    if (orderStatusState.cancelationStatus?.[0]?.canceled === true) {
      statuses.push({
        title: page.orderStatusLabels.unuOrderCanceledTitle,
        date: orderStatusState.cancelationStatus?.[0]?.cancelationDate,
        text: page.orderStatusLabels.unuOrderCanceledText,
        completed: true,
        isCancelation: true,
      })
    }

    return statuses
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusState])

  return (
    <ThemeProvider>
      <OrderOverviewContainer>
        <OrderOverviewInnerContainer>
          {!loading && <OrderStatusError page={page} error={error} />}

          {loading && (
            <LoaderContainer>
              <LoaderIcon dark />
            </LoaderContainer>
          )}

          {!loading && !error && (
            <TrackStatusSection>
              <TrackStatusSectionInner>
                <NextStepsTitle>
                  {page.orderStatusLabels.title.replace(
                    "{name}",
                    orderStatusState?.customerInfo?.[0]?.name
                  )}
                </NextStepsTitle>
                <TrackStatusSectionContent>
                  <ScooterInfoContainer>
                    <ScooterImageContainer>
                      <ScooterImage
                        sku={orderStatusState?.scooter?.[0]?.Colour}
                      />
                    </ScooterImageContainer>
                    <ScooterInfo>
                      <ScooterInfoTitle>
                        {page.orderStatusLabels.yourScooterTitle}
                      </ScooterInfoTitle>

                      <ScooterInfoContent>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.vin}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.VIN || "-"}
                        </ScooterInfoValue>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.producerKey}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.producerKey || "-"}
                        </ScooterInfoValue>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.vehicleClass}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.vehicleClass || "-"}
                        </ScooterInfoValue>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.modelName}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.ModelName || "-"}
                        </ScooterInfoValue>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.vehicleBrand}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.vehicleBrand || "-"}
                        </ScooterInfoValue>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.type}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.type || "-"}
                        </ScooterInfoValue>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.engine}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.variant}W
                        </ScooterInfoValue>
                        <ScooterInfoLabel>
                          {page.orderStatusLabels.version}
                        </ScooterInfoLabel>
                        <ScooterInfoValue>
                          {orderStatusState?.scooter?.[0]?.Version}
                        </ScooterInfoValue>
                      </ScooterInfoContent>
                      <ScooterInfoFooter>
                        {page.orderStatusLabels.yourScooterText}
                      </ScooterInfoFooter>
                    </ScooterInfo>
                  </ScooterInfoContainer>
                  <TrackOrderContainer>
                    <TrackOrderHeader>
                      <TrackOrderTitle>
                        {page.orderStatusLabels.trackOrderTitle}
                      </TrackOrderTitle>
                      <TrackOrderOrderNumber>
                        #{orderStatusState?.orderNumber}
                      </TrackOrderOrderNumber>
                    </TrackOrderHeader>
                    <TrackOrderContent>
                      {trackOrderSteps.map((step, index) => (
                        <TrackOrderItem key={index}>
                          <TrackOrderIcon {...step} />
                          <TrackOrderItemTitle>
                            {step.title}
                          </TrackOrderItemTitle>

                          {step.date ? (
                            <TrackOrderItemDate>
                              <ClockIcon /> {step.date}
                            </TrackOrderItemDate>
                          ) : null}

                          {step.text && (
                            <TrackOrderItemText>{step.text}</TrackOrderItemText>
                          )}
                        </TrackOrderItem>
                      ))}
                    </TrackOrderContent>
                  </TrackOrderContainer>
                </TrackStatusSectionContent>
              </TrackStatusSectionInner>
            </TrackStatusSection>
          )}
        </OrderOverviewInnerContainer>
      </OrderOverviewContainer>
    </ThemeProvider>
  )
}

const TrackOrderIcon = ({ completed, isDelivery, isCancelation }) => {
  if (isCancelation) {
    return (
      <CancelledTrackOrderStepIcon>
        <RawCloseIcon />
      </CancelledTrackOrderStepIcon>
    )
  }

  if (isDelivery) {
    return (
      <TrackOrderStepIcon success={completed}>
        <DeliveryIcon2 />
      </TrackOrderStepIcon>
    )
  }

  if (completed) {
    return (
      <TrackOrderStepIcon success>
        <CheckmarkIcon color="white" />
      </TrackOrderStepIcon>
    )
  }

  return (
    <TrackOrderStepIcon>
      <ClockIcon />
    </TrackOrderStepIcon>
  )
}

const OrderOverviewPage = ({ data, pageContext, location }) => {
  if (!data) return null

  const page = data.contentfulBuyUnuPage
  const {
    language,
    country,
    footerData,
    shipUnuPageSlug,
    pageMeta,
  } = pageContext

  const localizedNodes = data.allContentfulBuyUnuPage.nodes.map(n => ({
    ...n,
    slug: `/order/`,
  }))

  return (
    <GlobalValuesProvider value={data.contentfulGlobal}>
      <Layout
        language={language}
        country={country}
        mainData={data.contentfulMain}
        footerData={footerData}
        activeLink="configure"
        localizedNodes={localizedNodes}
      >
        <SEO
          title={page.orderCompleteTitle}
          description={page.metaDescription}
          localizedNodes={localizedNodes}
          pageMeta={pageMeta}
        />
        <OrderOverviewPageBody
          language={language}
          country={country}
          page={page}
          shipUnuPageSlug={shipUnuPageSlug}
        />
      </Layout>
    </GlobalValuesProvider>
  )
}

export default OrderOverviewPage

function OrderStatusError({ page, error }) {
  if (!error) return null

  return (
    <TrackStatusErrorContainer>
      <TrackStatusError>
        <NextStepsErrorTitle>
          {page.orderStatusLabels.orderLoadingErrorTitle}
        </NextStepsErrorTitle>
        <NextStepsErrorText>
          {page.orderStatusLabels.orderLoadingErrorText}
        </NextStepsErrorText>
      </TrackStatusError>
    </TrackStatusErrorContainer>
  )
}

const OrderOverviewContainer = styled.div`
  background: ${COLOR_STONE};
  position: relative;
`

const OrderOverviewInnerContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`

const TrackStatusSection = styled.section`
  // bleed content out of the container
  padding: 0 24px;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;

  ${breakpoints({
    SM: css``,
    MD: css``,
    LG: css``,
    XL: css``,
  })}
`

const TrackStatusSectionInner = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin-left: auto;
  margin-right: auto;

  ${breakpoints({
    SM: css`
      padding-top: 48px;
      padding-bottom: 48px;
    `,
    MD: css`
      padding-top: 50px;
      padding-bottom: 41px;
    `,
    LG: css`
      padding-top: 66px;
      padding-bottom: 57px;
    `,
    XL: css`
      padding-top: 66px;
      padding-bottom: 97px;
      padding-left: ${SPACE_XL}px;
      padding-right: ${SPACE_XL}px;
    `,
  })}
`

const TrackStatusErrorContainer = styled(TrackStatusSection)`
  border: none;
`

const TrackStatusError = styled(TrackStatusSectionInner)`
  padding-top: min(30%, 120px);

  ${breakpoints({
    SM: css`
      // padding-top: 30%;
      padding-bottom: 96px;
    `,
    MD: css`
      // padding-top: 50px;
      // padding-bottom: 41px;
    `,
    LG: css`
      // padding-top: 66px;
      // padding-bottom: 57px;
    `,
    XL: css`
      // padding-top: 66px;
      // padding-bottom: 97px;
      padding-left: ${SPACE_XL}px;
      padding-right: ${SPACE_XL}px;
    `,
  })}
`

const NextStepsTitle = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLOR_GRAY_900};

  ${breakpoints({
    SM: css`
      margin-bottom: 80px;
    `,
    MD: css`
      font-size: 36px;
      margin-bottom: 48px;
    `,
    LG: css`
      font-size: 32px;
      margin-bottom: 64px;
    `,
    XL: css`
      font-size: 36px;
      margin-bottom: 64px;
    `,
  })}
`

const NextStepsErrorTitle = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLOR_GRAY_900};

  ${breakpoints({
    SM: css`
      font-size: 22px;
      margin-bottom: 32px;
    `,
    MD: css`
      font-size: 24px;
      margin-bottom: 32px;
    `,
    LG: css`
      font-size: 32px;
      margin-bottom: 48px;
    `,
    XL: css`
      font-size: 36px;
      margin-bottom: 48px;
    `,
  })}
`
const NextStepsErrorText = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLOR_GRAY_900};

  ${breakpoints({
    SM: css`
      font-size: 16px;
    `,
    MD: css``,
    LG: css``,
    XL: css``,
  })}
`

const TrackStatusSectionContent = styled.div`
  ${breakpoints({
    SM: css``,
    MD: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    LG: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
    XL: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
  })}
`

const ScooterInfoContainer = styled.div`
  width: 100%;

  ${breakpoints({
    SM: css`
      // display: none;
    `,
    MD: css`
      margin-bottom: 48px;
    `,
    LG: css`
      width: calc(45% - 24px);
      margin-right: 20px;
    `,
    XL: css`
      width: 48%;
    `,
  })}
`

const ScooterImageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 64px;

  ${breakpoints({
    SM: css`
      display: none;
    `,
    MD: css`
      width: 335px;
      height: 335px;
    `,
    LG: css`
      max-width: 445px;
      flex: 1;
    `,
    XL: css`
      max-width: 445px;
      height: 445px;
      flex: 1;
    `,
  })}
`

const ScooterInfo = styled.div`
  background-color: white;
  border-radius: 20px;

  ${breakpoints({
    SM: css`
      background: none;
      border-radius: 0;
    `,
    MD: css``,
    LG: css`
      // width: clamp(380px, 49%, 410px);
      // min-width: 380px;
    `,
    XL: css`
      // width: 49%;
    `,
  })}
`

const ScooterInfoTitle = styled.div`
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: ${COLOR_GRAY_900};
  font-size: 23px;
  border-bottom: 0.5px solid ${COLOR_GRAY_300};
  padding: 48px 24px 48px;

  ${breakpoints({
    SM: css`
      font-size: 20px;

      // make the title border full width
      margin-left: -24px;
      margin-right: -24px;
      padding: 32px 24px 32px 48px;
    `,
    MD: css``,
    LG: css``,
    XL: css``,
  })}
`
const ScooterInfoContent = styled.div`
  // display: flex;
  // flex-direction: row;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  margin-top: 32px;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  margin-bottom: 32px;
  padding: 24px 24px 48px;

  ${breakpoints({
    SM: css`
      margin-top: 24px;
      grid-row-gap: 8px;
      grid-column-gap: 8px;
    `,
  })}
`

const ScooterInfoFooter = styled.div`
  padding: 0 24px 48px;

  ${breakpoints({
    SM: css`
      padding: 0 24px 24px;
    `,
  })}
`

const ScooterInfoLabel = styled.div`
  font-weight: 600;
  margin-right: 20px;
`
const ScooterInfoValue = styled.div``

const ScooterImg = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${breakpoints({
    MD: css`
      margin-left: auto;
      margin-right: auto;
    `,
  })}
`

const TrackOrderContainer = styled.div`
  background-color: white;
  border-radius: 20px;

  ${breakpoints({
    SM: css`
      background: none;
      border-radius: 0;
    `,
    MD: css``,
    LG: css`
      width: 55%;
    `,
    XL: css`
      width: 48%;
    `,
  })}
`

const TrackOrderHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 48px 24px;
  border-bottom: 0.5px solid ${COLOR_GRAY_300};

  ${breakpoints({
    SM: css`
      // make the title border full width
      margin-left: -24px;
      margin-right: -24px;
      padding: 32px 24px 32px 48px;
    `,
    MD: css``,
  })}
`
const TrackOrderTitle = styled.div`
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: ${COLOR_GRAY_900};
  font-size: 23px;

  ${breakpoints({
    SM: css`
      font-size: 20px;
    `,
    MD: css``,
    LG: css``,
    XL: css``,
  })}
`

const TrackOrderOrderNumber = styled.div`
  font-weight: 600;
  font-size: 23px;
  line-height: 130%;
  text-align: right;
  letter-spacing: -0.01em;
  color: ${COLOR_BLUE};

  ${breakpoints({
    SM: css`
      font-size: 20px;
    `,
    MD: css``,
    LG: css``,
    XL: css``,
  })}
`

const TrackOrderContent = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${COLOR_GRAY_900};
  padding: 48px 24px;

  ${breakpoints({
    SM: css`
      padding: 32px 24px;
    `,
    MD: css`
      font-size: 16px;
    `,
    LG: css`
      font-size: 16px;
    `,
    XL: css`
      font-size: 18px;
    `,
  })}
`

const TrackOrderStepIcon = styled.div`
  position: absolute;
  left: -18px;
  background: ${props => (props.success ? COLOR_SUCCESS : COLOR_GRAY_900)};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  top: -3px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    fill: white !important;
  }

  ${breakpoints({
    SM: css`
      left: -12px;
      width: 24px;
      height: 24px;
    `,
  })}
`

const CancelledTrackOrderStepIcon = styled(TrackOrderStepIcon)`
  background: ${COLOR_GRAY_900};
  // border: 1px solid ${COLOR_GRAY_900};

  svg {
    transform: scale(0.8);
  }

  svg path {
    // fill: ${COLOR_ERROR} !important;
  }
`

const TrackOrderItem = styled.div`
  position: relative;
  padding-left: 50px;
  padding-bottom: 32px;
  border-left: 2px dashed ${COLOR_SUCCESS};
  margin-left: 18px;

  &:last-child {
    border-left: none;
  }

  ${breakpoints({
    SM: css`
      padding-bottom: 24px;
      padding-left: 40px;
      margin-left: 12px;
    `,
  })}
`

const TrackOrderItemTitle = styled.div`
  font-weight: 600;
  font-size: 23px;
  line-height: 1;
  letter-spacing: -0.01em;
  color: ${COLOR_GRAY_900};
  margin-bottom: 8px;

  ${breakpoints({
    SM: css`
      font-size: 20px;
    `,
    MD: css``,
    LG: css``,
    XL: css``,
  })}
`

const TrackOrderItemDate = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${COLOR_GRAY_400};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;

    path {
      fill: ${COLOR_GRAY_400} !important;
    }
  }

  ${breakpoints({
    SM: css``,
    MD: css``,
    LG: css``,
    XL: css`
      font-size: 14px;
    `,
  })}
`

const TrackOrderItemText = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: ${COLOR_GRAY_900};

  ${breakpoints({
    SM: css`
      font-size: 13px;
    `,
    MD: css`
      font-size: 16px;
    `,
    LG: css`
      font-size: 16px;
    `,
    XL: css``,
  })}
`
const TrackOrderInfoRow = styled.div`
  // display: flex;
  // flex-direction: row;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  margin-top: 32px;
  grid-row-gap: 16px;
  grid-column-gap: 16px;

  ${breakpoints({
    SM: css`
      margin-top: 24px;
      grid-row-gap: 8px;
      grid-column-gap: 8px;
    `,
  })}
`
const TrackOrderInfoLabel = styled.div`
  font-weight: 600;
  margin-right: 20px;
`
const TrackOrderInfoValue = styled.div``

const images = {
  red: "/images/scooters/red.png",
  coral: "/images/scooters/coral.png",
  black: "/images/scooters/black.png",
  blue: "/images/scooters/blue.png",
  pine: "/images/scooters/pine.png",
  white: "/images/scooters/white.png",
  stone: "/images/scooters/stone.png",
}

const ScooterImage = ({ sku }) => {
  const color = getColor(sku)
  const scooterImage = images[color]
  const altText = color ? `${color} scooter` : "scooter"
  return <ScooterImg src={scooterImage} alt={altText} />
}

function getColor(sku) {
  if (!sku) return "black" //temporarily fix for netsuite response data

  // newer SKUs are straightforward to pull color from
  // but there's a few other standards to cover as well
  // if we have the new standard, great
  const colorCode = sku
  if (colorCode) {
    const colorMap = {
      MPNK: "coral",
      GBLU: "blue",
      GRED: "red",
      MGRN: "pine",
      MWHT: "white",
      MBGE: "stone",
      MBLK: "black",
    }

    return colorMap[colorCode] || "black"
  }

  // otherwise try to search substrings and map to color
  // this will be rare occurences in either way since older customers won't be checking the order status page
  const codedColor = sku.toLowerCase().split("-")[2]
  if (codedColor.includes("black") || codedColor.includes("blk")) return "black"
  if (
    codedColor.includes("blue") ||
    codedColor.includes("navbl") ||
    codedColor.includes("blu")
  )
    return "blue"
  if (codedColor.includes("red")) return "red"
  if (
    codedColor.includes("rose") ||
    codedColor.includes("pink") ||
    codedColor.includes("pnk")
  )
    return "coral"
  if (
    codedColor.includes("prlwh") ||
    codedColor.includes("mwht") ||
    codedColor.includes("wht")
  )
    return "white"
  if (codedColor.includes("gry")) return "stone"

  return "black"
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 900px;

  ${breakpoints({
    SM: css`
      min-height: 480px;
    `,
    MD: css`
      min-height: 640px;
    `,
  })}
`
