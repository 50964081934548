export function buildLocalizedSlug(slugPrefix, pagePath) {
  return slugPrefix.replace(/\/+$/, "") + "/" + pagePath.replace(/^\/+/, "")
}

export function buildAbsoluteLocalizedSlug(slugPrefix, pagePath) {
  const url =
    slugPrefix.replace(/\/+$/, "") + "/" + pagePath.replace(/^\/+/, "")
  return url.startsWith("/") ? url : `/${url}`
}

export function getQueryStringValue(query, key) {
  return decodeURIComponent(
    query.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  )
}
