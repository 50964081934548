export async function getOrderStatusApi(orderGuid) {
  const controller = new AbortController()

  const timeoutId = setTimeout(() => {
    controller.abort()
  }, 15000)

  try {
    const response = await fetch(
      `/.netlify/functions/netsuite?guid=${orderGuid}`,
      {
        method: "GET",
        credentials: "same-origin",
        mode: "same-origin",
        signal: controller.signal,
      }
    )

    console.log("cart-provider response: ", response)

    if (!response.ok) {
      throw new Error(`${response.status} - ${response.title}`)
    }

    const data = await response.json()

    clearTimeout(timeoutId)
    if (data.statusCode === 404) {
      throw new Error("Couldn't find your order")
    }
    return data
  } catch (error) {
    clearTimeout(timeoutId)
    console.error(error)
    throw error
  }
}

